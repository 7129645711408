







import { Component, Prop, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import G6 from '@antv/g6'
import clickPng from '@/assets/img/click.png'

const { uniqueId } = G6.Util
import { isNumber, isArray } from '@antv/util'

@Component({
  name: 'LinkPicture',
})
export default class LinkPicture extends VueBase {
  @Prop() data!: any

  @Emit('openRight')
  private openRight(flag: any, node: any) {
    return [flag, node]
  }
  private json_data: any = require('../../json_data.json')

  private souce_data: any = {}
  private GraphHandel: any = null

  private lineHight(graph: any) {
    const edges = graph.getEdges()
    edges.forEach((item: any) => {
      graph.setItemState(item, 'select', false)
      if (
        item.get('model').project_path ===
        Object.keys(this.data.path_graphs_dict)[0]
      ) {
        graph.setItemState(item, 'select', true)
      }
    })
    // 线的两边
    graph.getNodes().forEach((item: any) => {
      graph.setItemState(item, 'selected', false)
    })
    edges.forEach((item: any) => {
      if (
        item.get('model').project_path ===
        Object.keys(this.data.path_graphs_dict)[0]
      ) {
        const node1 = item.getSource()
        const node2 = item.getTarget()
        graph.setItemState(node1, 'selected', true)
        graph.setItemState(node2, 'selected', true)
      }
    })
  }
  private init() {
    // 测试数据 this.json_data.data.graphsv3-真实数据data
    const nodes = this.data.project_nodes.map((item: any) => {
      return {
        project__name: item.project__name,
        project_id: item.project_id,
        id: item.id,
        project_path: item.project_path,
        label: item.project__name,
        type: 'user_auth',
        stroke: '#4a72ae',
        img: `/upload/assets/links/JAVA.png`,
        opacity: 0.8,
        stateStyles: {
          selected: {
            // #26A6F5
            shadowColor: '#4a72ae',
            shadowBlur: 20,
            fill: '#fff',
            stroke: '#4a72ae',
            lineWidth: 2,
            cursor: 'pointer',
          },
          hover: {
            shadowColor: '#4a72ae',
            shadowBlur: 8,
            fill: '#fff',
            stroke: '#4a72ae',
            lineWidth: 2,
            cursor: 'pointer',
          },
        },
      }
    })
    const edges = this.data.project_vecs.map((item: any) => {
      return {
        source: item.from,
        target: item.to,
        img: clickPng,
        project_path: item.project_path,
        title: 'title',
      }
    })
    this.souce_data = {
      nodes,
      edges,
    }
    console.log('this.souce_data', this.souce_data)
    const container = document.getElementById('mountNode2') as HTMLElement
    const width = container.scrollWidth
    const height = container.scrollHeight || 500
    this.run(width, height)
  }

  private async run(height: number, width: number) {
    const minimap = new G6.Minimap({
      container: 'minimap',
      size: [100, 100],
    })
    const tooltip = new G6.Tooltip({
      offsetX: 20,
      offsetY: 30,
      itemTypes: ['edge'],
      fixToNode: [0.5, 0.5],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e: any) => {
        return `查看当前链路Service Beta污点流`
      },
      shouldBegin: (e: any) => {
        console.log(e.target)
        let res = true
        return res
      },
    })
    const graph = new G6.Graph({
      container: 'mountNode2',
      // fitView: true,
      fitCenter: true,
      fitViewPadding: 10,
      layout: {
        type: 'dagre',
        animate: false,
        rankdir: 'LR',
        nodesep: 40,
        ranksep: 50,
        sortByCombo: true,
      },
      defaultEdge: {
        type: 'line-dash',
        loopCfg: {
          dist: 40,
        },
        labelCfg: {
          style: {
            fill: '#959FB4',
            stroke: '#E6E9EC',
          },
        },
      },
      defaultNode: {
        size: 20,
      },
      modes: {
        default: [
          'zoom-canvas',
          'drag-canvas',
          'drag-node',
          {
            type: 'tooltip',
            formatText(model: any) {
              console.log(model)
              switch (model.type) {
                case 'inner-img':
                  return `Agent: ${model.tooltip}`
                case 'my-image':
                  let modelDom = ''
                  let mysqlDom = `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${model.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${model.address}</span></div>`
                  model.meta &&
                    model.meta.forEach((item: any) => {
                      modelDom += `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${item.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${item.address}</span></div>`
                    })

                  return `<div style="width: 300px;padding: 8px;font-size:14px;">
                   <div style="color: #4a72ae;">DB: ${model.service_type}</div> 
                    ${modelDom || mysqlDom}
                  </div> `
                case 'user_auth':
                  return `项目: ${model.project__name}`
                case 'line-dash':
                  return `项目: ${model.project__name}`
                default:
                  return ``
              }
            },
            shouldUpdate: (e: any) => true,
          },
        ],
      },
      plugins: [minimap, tooltip],
    })

    graph.on('edge:mouseenter', function (evt) {
      const edge = evt.item as any
      const model = edge.getModel()
      model.oriLabel = model.label
      graph.setItemState(edge, 'hover', true)
    })
    graph.on('edge:mouseleave', function (evt) {
      const edge = evt.item as any
      graph.setItemState(edge, 'hover', false)
    })
    graph.on('node:mouseenter', (e: any) => {
      const item = e.item as any
      graph.getEdges().forEach(function (edge) {
        if (edge.getSource() === item) {
          graph.setItemState(edge.getTarget(), 'hover', true)
          edge.toFront()
        } else if (edge.getTarget() === item) {
          graph.setItemState(edge.getSource(), 'hover', true)
          edge.toFront()
        } else {
          graph.setItemState(edge, 'hover', false)
        }
      })
      graph.setItemState(e.item, 'hover', true)
    })

    graph.on('node:mouseleave', (e: any) => {
      const item = e.item as any
      console.log('e.item', e.item)
      graph.getEdges().forEach(function (edge) {
        if (edge.getSource() === item) {
          graph.setItemState(edge.getTarget(), 'hover', false)
        } else if (edge.getTarget() === item) {
          graph.setItemState(edge.getSource(), 'hover', false)
        } else {
          graph.setItemState(edge, 'hover', false)
        }
      })
      graph.setItemState(e.item, 'hover', false)
    })

    graph.on('edge:click', (e: any) => {
      const item = e.item as any
      const model = item.get('model')
      console.log('model', model)

      this.openRight(true, model)
      const hasSelected = item.hasState('selected')
      if (!hasSelected) {
        // 高亮
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      } else {
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      }
      // 找到相同的线路

      // 线的两边
      graph.getNodes().forEach((item: any) => {
        graph.setItemState(item, 'selected', false)
      })
      const edges = graph.getEdges()
      edges.forEach((item: any) => {
        if (item.get('model').project_path === model.project_path) {
          const node1 = item.getSource()
          const node2 = item.getTarget()
          graph.setItemState(node1, 'selected', true)
          graph.setItemState(node2, 'selected', true)
        }
      })
    })

    graph.on('node:click', (e: any) => {
      const item = e.item as any
      const edge = item.getOutEdges()[0]
      if (!edge) {
        return
      }
      const model = edge.get('model')

      console.log('model', model)

      this.openRight(true, model)
      const hasSelected = item.hasState('selected')
      if (!hasSelected) {
        // 高亮
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      } else {
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      }
      // 找到相同的线路

      // 线的两边
      graph.getNodes().forEach((item: any) => {
        graph.setItemState(item, 'selected', false)
      })
      const edges = graph.getEdges()
      edges.forEach((item: any) => {
        if (item.get('model').project_path === model.project_path) {
          const node1 = item.getSource()
          const node2 = item.getTarget()
          graph.setItemState(node1, 'selected', true)
          graph.setItemState(node2, 'selected', true)
        }
      })
    })

    // const edges = node.getOutEdges();

    G6.registerNode(
      'user_auth',
      {
        draw(cfg: any, group: any) {
          const { title = '', size, label, stroke } = cfg
          // 设置
          const rectConfig = {
            width: 50,
            height: 50,
            lineWidth: 2,
            fontSize: 14,
            fill: '#fff',
            cursor: 'pointer',
            radius: 4,
            stroke: stroke,
            opacity: 1,
          }

          const nodeOrigin = {
            x: -rectConfig.width / 2,
            y: -rectConfig.height / 2,
          }

          let rect = group.addShape('circle', {
            attrs: {
              x: nodeOrigin.x,
              y: nodeOrigin.y,
              r: 25,
              ...rectConfig,
            },
            draggable: true,
            name: 'marker-circle',
          })
          group.addShape('image', {
            attrs: {
              x: -rectConfig.width + 12.5,
              y: -rectConfig.height + 12.5,
              width: 25,
              height: 25,
              img: cfg.img,
              opacity: 1,
              fillOpacity: 1,
            },
            draggable: true,
            name: 'image-Shape',
          })
          // group.addShape('text', {
          //   // attrs: style
          //   attrs: {
          //     x: nodeOrigin.x, // 居中
          //     y: nodeOrigin.y,
          //     textAlign: 'center',
          //     textBaseline: 'middle',
          //     text: cfg.project_id,
          //     fill: '#4a72ae',
          //     fontsize: 12,
          //   },
          //   name: 'text2-shape',
          //   // 设置 draggable 以允许响应鼠标的图拽事件
          //   draggable: true,
          // })
          if (cfg.label) {
            // 如果有文本
            // 如果需要复杂的文本配置项，可以通过 labeCfg 传入
            // const style = (cfg.labelCfg && cfg.labelCfg.style) || {};
            // style.text = cfg.label;
            const label = group.addShape('text', {
              // attrs: style
              attrs: {
                x: nodeOrigin.x, // 居中
                y: nodeOrigin.y - 40,
                textAlign: 'center',
                textBaseline: 'middle',
                text:
                  cfg.label.length > 16
                    ? `${(cfg.label as string).substring(0, 16)}...`
                    : cfg.label,
                fill: '#4a72ae',
                fontsize: 12,
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape',
              // 设置 draggable 以允许响应鼠标的图拽事件
              draggable: true,
            })
          }

          return rect
        },
      },
      'circle'
    )

    function refreshDragedNodePosition(e: any) {
      const model = e.item.get('model')
      model.fx = e.x
      model.fy = e.y
    }
    graph.on('node:dragstart', (e) => {
      graph.get('layoutController').data.nodes.forEach((node: any) => {
        node.fx = node.x
        node.fy = node.y
      })
      // graph.layout()
      // refreshDragedNodePosition(e)
    })
    graph.on('node:drag', (e) => {
      refreshDragedNodePosition(e)
      // graph.layout()
    })
    const lineDash = [5, 5]
    G6.registerEdge(
      'line-dash',
      {
        drawShape: (cfg: any, group: any) => {
          const startPoint = cfg.startPoint
          const endPoint = cfg.endPoint
          const keyShape = group.addShape('path', {
            attrs: {
              endArrow: {
                path: 'M 0,0 L 6,4 L 6,-4 Z', // 自定义箭头路径
                d: 0, // 偏移量
                lineDash: [10, 0],
                lineWidth: 4,
                stroke: '#5a78e8',
                fill: '#5a78e8',
              },
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
              ],
              stroke: '#5a78e8',
              lineDash: [5, 5],
              lineWidth: 4,
              strokeOpacity: '0.6',
              cursor: 'pointer',
            },
          })
          // const keyShape = group.addShape('image', {
          //   attrs: {
          //     img: cfg.img,
          //     x: (startPoint.x + endPoint.x) * 0.5,
          //     y: (startPoint.y + endPoint.y) * 0.5 - 6,
          //     cursor: 'pointer',
          //     width: 25,
          //     height: 25,
          //     opacity: 1,
          //     fillOpacity: 1,
          //   },
          // })
          return keyShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'hover') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 1.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.8)
              })
            }
          }
          if (name === 'select') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('lineWidth', 5)
                item.attr('shadowColor', '#0099CC')
                item.attr('shadowBlur', 20)
                item.attr('strokeOpacity', 1)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('lineWidth', 4)
                item.attr('shadowColor', 'none')
                item.attr('shadowBlur', '0')
                item.attr('strokeOpacity', 0.8)
              })
            }
          }
        },
        update: undefined,
      },
      'line'
    ) // 该自定义边继承 quadratic

    graph.data(this.souce_data)
    graph.render()
    this.lineHight(graph)
  }

  mounted() {
    this.init()
  }
}
